import {createGenericApi} from "./createGenericApi";
import {API_SUBSCRIBER} from "Models/Subscriber/SubscriberEndpoints";

export const subscriberApi = createGenericApi({
    reducerPath: "subscriberApi",
    baseUrl: API_SUBSCRIBER.subscribers,
    tagTypes: ["Subscriber"],
});

export const {
    useFetchItemsQuery: useFetchSubscribersQuery,
    useAddItemMutation: useAddSubscriberMutation,
    useUpdateItemMutation: useUpdateSubscriberMutation,
    useDeleteItemMutation: useDeleteSubscriberMutation,
} = subscriberApi;
