import React, {useContext, useEffect, useState, useRef} from "react";
import {Form} from "react-bootstrap";
import {useForm} from "react-hook-form";
import {makeGetRequest, makePatchRequest, makePostRequest, makeUpdateRequest} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSTextArea} from "Components/Common/GSPSTextAreaInput";
import {UserContext} from "App";
import {GSPSContainer} from "Components/Common/GSPSLabeledInput.styles";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import {Autocomplete, Button, TextField} from "@mui/material";
import {InfoForm} from "./InfoForm.styles";


export const ReviewerInformation = ({
    audit,
    users,
    readonly,
    PublicRecord,
}) => {
    const {handleSubmit, register, setValue, formState: {errors}} = useForm();
    const {user} = useContext(UserContext) || {};
    const [selectedReviewer, setSelectedReviewer] = useState();
    const [reviewerType, setReviewerType] = useState("submit");
    const [reviewer, setReviewer] = useState();
    const navigate = useNavigate();
    const endpointToUseAudit = PublicRecord ? API_AUDITS.publicAudits : API_AUDITS.audits;
    const [inReview, setInReview] = useState(false);
    const autosaveNotesTimeout = useRef(null);
    const [notes, setNotes] = useState(audit?.auditor_additional_notes ?? "");

    useEffect(() => {
        let reviewer = null;
        if (audit) {
            makeGetRequest(`${endpointToUseAudit}${audit.id}/`).then((res) => {
                reviewer = res.reviewer || user;
                setSelectedReviewer(reviewer);
                setValue("reviewer_id", reviewer.id, {shouldValidate: true});
                setValue("phone", reviewer.mobile_phone);
                setValue("email", reviewer.email);
                setInReview(audit.audit_status.name === "In Review");
            }).catch((e) => insertNotification("Error", `Can't get audit ${audit} details`, "error"));
        } else {
            reviewer = user;
            setSelectedReviewer(reviewer);
            setValue("reviewer_id", reviewer.id, {shouldValidate: true});
            setValue("phone", reviewer.mobile_phone);
            setValue("email", reviewer.email);
        }
    }, [audit, user, setValue]);
    useEffect(() => {
        setNotes(audit?.reviewer_additional_notes ?? "");
    }, [audit]);
    useEffect(() => {
        if (selectedReviewer) {
            setValue("reviewer_id", selectedReviewer.id, {shouldValidate: true});
            setValue("phone", selectedReviewer.mobile_phone);
            setValue("email", selectedReviewer.email);
        }
    }, [selectedReviewer, setReviewer]);

    const onSubmit = async (values) => {
        values.submission_type = reviewerType;
        if (inReview) {
            try {
                makePostRequest(`${endpointToUseAudit}${audit.id}/reviewer/`, values)
                    .then((res) => {
                        if (values.submission_type == "submit") {
                            insertNotification("Success", `${audit.name} completed successfully ..`, "success");
                        } else {
                            insertNotification("Success", `${audit.name} is set to edits required successfully ..`, "success");
                        }
                        navigate("/");
                    });
            } catch (e) {
                if (e instanceof TypeError) {
                    insertNotification("Error", "Can't set audit reviewer, please save the audit first.", "error");
                } else {
                    insertNotification("Error", `Can't set audit reviewer ${e}`, "error");
                }
            }
        } else {
            insertNotification("Error", "Audit must be in 'In Review' status to perform this action.", "error");
        }
    };
    const updateNotes = async (notes) => {
        clearTimeout(autosaveNotesTimeout.current);
        autosaveNotesTimeout.current = setTimeout(async () => {
            if (audit && notes != audit?.reviewer_additional_notes && !readonly) {
                const updatedAudit = {
                    reviewer_additional_notes: notes,
                };
                makePatchRequest(`${API_AUDITS.audits}${audit.id}/`, updatedAudit);
            }
        }, 1000);
    };

    return (
        <InfoForm>
            <div className="container" id="reviewer">
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <h1 className="subtitle-md">
                        Reviewer Information
                    </h1>
                    <div className="row">
                        <div className="col-md-8 col-12 ps-4 d-flex flex-column justify-content-between">
                            {!readonly && <div>
                                <div className="row">
                                    <h2 className="col-md-5 col-12 body-md d-flex align-items-center">
                                        Reviewer Name
                                    </h2>
                                    <div className="col">
                                        <Autocomplete
                                            disablePortal
                                            options={users?.map((user) => ({
                                                label: user.full_name,
                                                id: user.id,
                                            }))}
                                            onChange={(e, newValue) => {
                                                const selectedUser = users.find((u) => u.id === +newValue.id);
                                                setSelectedReviewer(selectedUser);
                                            }}
                                            renderInput={(params) => <TextField {...params} />}
                                            size="small"
                                        />
                                    </div>
                                </div>
                            </div>
                            }

                            {readonly && users?.length === 1 &&
                                <div className="row">
                                    <h2 className="col-md-5 col-12 body-md d-flex align-items-center">
                                        Reviewer Name
                                    </h2>
                                    <div className="col">
                                        <GSPSLabelledInput
                                            controlType={"input"}
                                            id={"Reviewer"}
                                            labelName={"Reviewer"}
                                            errors={errors}
                                            register={register}
                                            isDisabled={true}
                                            hiddenLabel
                                            value={users?.map((u) => u?.full_name)}
                                        />
                                    </div>
                                </div>}

                            <div className="row">
                                <h2 className="col-md-5 col-12 body-md d-flex align-items-center">
                                    Phone number
                                </h2>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"phone"}
                                        labelName={"Phone"}
                                        errors={errors}
                                        register={register}
                                        isDisabled={true}
                                        hiddenLabel
                                        value={selectedReviewer?.mobile_phone}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <h2 className="col-md-5 col-12 body-md d-flex align-items-center">
                                    Email
                                </h2>
                                <div className="col">
                                    <GSPSLabelledInput
                                        controlType={"input"}
                                        id={"email"}
                                        labelName={"Email"}
                                        errors={errors}
                                        isDisabled={true}
                                        register={register}
                                        hiddenLabel
                                        value={selectedReviewer?.email}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-12">
                            <h2 className="body-md mb-2">
                                Additional Notes
                            </h2>
                            <GSPSTextArea
                                value={audit?.reviewer_additional_notes}
                                labelName={"Additional Notes"}
                                id={"additional_notes"}
                                controlType={"input"}
                                isRequired={false}
                                isDisabled={readonly}
                                register={register}
                                rows={7}
                                errors={errors}
                                setValue={setValue}
                                charLimit={700}
                                hiddenLabel
                                hiddenCharLimit
                                setDescription={setNotes}
                                autoSave={true}
                                updateOnChange={updateNotes}
                            />
                        </div>
                        {
                            !readonly && <div className="float-end">
                                <Button type="submit" variant={"contained"} onClick={() => setReviewerType("submit")} className="float-end mt-3 cta-btn">
                                    Submit
                                </Button>
                                <Button type="submit" variant={"outlined"} onClick={() => setReviewerType("requestEdits")} className="float-end me-3 mt-3 cta-btn-secondary">
                                    Request Edits
                                </Button>
                            </div>
                        }
                    </div>
                </Form>
            </div>
        </InfoForm>);
};

ReviewerInformation.propTypes = {
    audit: PropTypes.object,
    users: PropTypes.array,
    readonly: PropTypes.bool,
};
