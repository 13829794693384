import {configureStore} from "@reduxjs/toolkit";
import {accountApi} from "api/AccountTableAPI";
import {auditApi} from "api/AuditTableAPI";
import {locationApi} from "api/LocationTableAPI";
import {userApi} from "api/UserTableAPI";
import {subscriberApi} from "api/SubasciberTableAPI";

export const store = configureStore({
    reducer: {
        [locationApi.reducerPath]: locationApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [accountApi.reducerPath]: accountApi.reducer,
        [auditApi.reducerPath]: auditApi.reducer,
        [subscriberApi.reducerPath]: subscriberApi.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(locationApi.middleware)
            .concat(userApi.middleware)
            .concat(auditApi.middleware)
            .concat(accountApi.middleware)
            .concat(subscriberApi.middleware),
});
