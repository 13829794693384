import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "Utils/helpers";

export const createGenericApi = ({
    reducerPath,
    baseUrl,
    tagTypes = [],
    cacheTimeout = 60,
    extraEndpoints = {},
}) => {
    return createApi({
        reducerPath,
        baseQuery: baseQueryWithReauth(baseUrl),
        tagTypes,
        endpoints: (builder) => ({
            fetchItems: builder.query({
                query: ({page = 1, pageSize = 10, mode = "table", active, filters = {}}) => {
                    const filterParams = new URLSearchParams({
                        page,
                        page_size: pageSize,
                        mode,
                        ...Object.fromEntries(
                            Object.entries(filters).map(([key, value]) => [key, `${value}`]),
                        ),
                    });
                    if (active !== undefined && active !== null) {
                        filterParams.append("active", active);
                    }
                    return `?${filterParams.toString()}`;
                },
                transformResponse: (response) => ({
                    data: response.results,
                    totalRowCount: response.count,
                }),
                providesTags: (result) =>
                    result ?
                        [
                            ...result.data.map(({id}) => ({type: tagTypes[0], id})),
                            tagTypes[0],
                        ] :
                        [tagTypes[0]],
                keepUnusedDataFor: cacheTimeout,
            }),

            archiveItem: builder.mutation({
                query: (id) => ({
                    url: `${id}/`,
                    method: "PATCH",
                    body: {active: false},
                }),
                invalidatesTags: (result, error, id) => [
                    {type: tagTypes[0], id},
                    tagTypes[0],
                    tagTypes[1],
                ],
            }),
            unarchiveItem: builder.mutation({
                query: (id) => ({
                    url: `${id}/`,
                    method: "PATCH",
                    body: {active: true},
                }),
                invalidatesTags: (result, error, id) => [
                    {type: tagTypes[1], id},
                    tagTypes[0],
                    tagTypes[1],
                ],
            }),
            deleteItem: builder.mutation({
                query: (id) => ({
                    url: `${id}/`,
                    method: "DELETE",
                }),
                invalidatesTags: (result, error, id) => [
                    {type: tagTypes[0], id},
                    {type: tagTypes[1], id},
                ],
            }),
            cloneItem: builder.mutation({
                query: (id) => ({
                    url: `${id}/clone/`,
                    method: "POST",
                }),
                invalidatesTags: tagTypes,
            }),
            addItem: builder.mutation({
                query: (newItem) => ({
                    url: "",
                    method: "POST",
                    body: newItem,
                }),
                invalidatesTags: tagTypes,
            }),
            updateItem: builder.mutation({
                query: ({id, updatedItem}) =>{
                    console.log(updatedItem);

                    return ({
                        url: `${id}/`,
                        method: "PUT",
                        body: updatedItem,
                        headers: {
                            "Content-Type": "application/json",
                        },
                    });
                },
                invalidatesTags: (result, error, {id}) => [{type: tagTypes[0], id}],
            }),
            ...extraEndpoints,
        }),
    });
};
