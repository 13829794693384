import {BtnsContainer, StyledSubscriberRow} from "Pages/Subscriber/Subscriber.styles";
import {Col, Form} from "react-bootstrap";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {AddressForm} from "Components/Common/AddressForm";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import {GSPSFormImageInput} from "Components/Common/GSPSFormImageInput";
import React from "react";
import {useForm} from "react-hook-form";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";

export const SubscriberFormFields = ({
    onSubmit,
    isSubmitting,
    defaultUnits = null,
    industries = null,
    setHasLogo = null,
    subscriber = null}) => {
    const {handleSubmit, register, setValue, formState: {isDirty, touched, errors}} = useForm();

    const navigate = useNavigate();

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <StyledSubscriberRow>
                <Col xs={10} md={5}>
                    <GSPSLabelledInput
                        id="subscriber_name"
                        controlType={"input"}
                        value={subscriber?.name}
                        labelName={"Name"}
                        isRequired={true}
                        register={register}
                        errors={errors} />

                    <AddressForm
                        id="address"
                        register={register}
                        errors={errors}
                        caller={subscriber}
                        setValue={setValue}
                    />

                    <GSPSLabelledInput
                        id="website"
                        controlType={"input"}
                        value={subscriber?.website}
                        labelName={"Website"}
                        register={register}
                        errors={errors} />

                    <GSPSLabelledInput
                        id="phone"
                        controlType={"input"}
                        value={subscriber?.phone}
                        labelName={"Phone"}
                        register={register}
                        errors={errors}
                        isPhone={true} />

                    {industries &&
                    <GSPSLabelledSelectField
                        labelName={"Industry"}
                        defaultValue={subscriber?.industry}
                        inputID={"industry"}
                        selectableOptions={industries}
                        register={register}
                        errors={errors}
                    />}
                </Col>

                <Col xs={10} md={5}>
                    {defaultUnits &&
                        <GSPSLabelledSelectField
                            labelName={"Default Units"}
                            defaultValue={subscriber?.default_unit}
                            inputID={"default_unit"}
                            selectableOptions={defaultUnits}
                            register={register}
                            errors={errors}
                            isRequired={true}
                        />
                    }
                    <GSPSFormImageInput
                        id={"logo"}
                        labelName={"Add Logo"}
                        labelSize={5}
                        controlSize={5}
                        imageContainerWidthPx={290}
                        imageContainerHeightPx={175}
                        setValue={setValue}
                        currentImageSrc={subscriber?.logo}
                        handleDeleteImage={() => {
                            setHasLogo(false);
                        }}
                        setHasLogo={setHasLogo}
                    />

                </Col>
            </StyledSubscriberRow>
            <BtnsContainer>
                <GSPSCancelButton
                    controlSize={0}
                    offset={0}
                    buttonText={"Cancel"}
                    secondaryBtn={true}
                    onClick={() => navigate("/subscribers")}
                />
                <GSPSSubmitButton isDisabled={(!isDirty && touched) ||
                                    errors.zip?.message.length > 0 || isSubmitting}
                isLoading={isSubmitting}
                controlSize={0}
                offset={0}
                padding={0}
                buttonText={"Save"}
                onClick={handleSubmit(onSubmit)} />
            </BtnsContainer>
        </Form>
    );
};

SubscriberFormFields.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    subscriber: PropTypes.object,
    defaultUnits: PropTypes.string,
    industries: PropTypes.string,
    setHasLogo: PropTypes.func,

};
