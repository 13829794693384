import React, {useEffect, useState} from "react";
import {fetchSelectionOptions} from "Services/ServicesUtils";
import {API_USERS} from "Services/Endpoints";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {GSPSLabelledSelectField} from "Components/Common/GSPSLabelledSelectField";
import PropTypes from "prop-types";
import * as TimeZonesUtils from "Services/TimeZonesUtils";
import {UserPermissions} from "Models/User/UserUtils";
import {UserPermissionGroupIDs} from "Models/User/UserConstants";
import {GSPSInputContainer, GSPSPopUpFooter} from "Components/Common/GSPSStyledContainers.styles";
import {GSPSCancelButton} from "Components/Common/GSPSCancelButton";
import {GSPSEnableDisableSwitch} from "Components/Common/GSPSEnableDisableSwitch";

export const UserFormFields = ({onSubmit, setShow, user = null}) => {
    const {handleSubmit, register, formState: {errors}} = useForm();
    const isAdmin = UserPermissions.useIsAdmin();
    const isGroupAdmin = UserPermissions.useIsGroupAdmin();

    const [userTitles, setUserTitles] = useState();
    const [userGroupPermissions, setUserGroupPermissions] = useState([]);
    const [userIndustryTypes, setUserIndustryTypes] = useState();

    useEffect(() => {
        fetchSelectionOptions(API_USERS.roles, setUserTitles);
        fetchSelectionOptions(API_USERS.groupPermissions, setUserGroupPermissions);
        fetchSelectionOptions(API_USERS.industryTypes, setUserIndustryTypes);
    }, []);

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                <Col xs={10} md={6}>
                    <GSPSInputContainer>
                        <GSPSLabelledInput
                            id="email"
                            controlType={"input"}
                            value={user?.email}
                            labelName={"Email"}
                            isRequired={true}
                            readOnly={!!user}
                            register={register}
                            errors={errors}
                        />
                    </GSPSInputContainer>
                </Col>
                <Col xs={10} md={6}>
                    <GSPSInputContainer>
                        <GSPSLabelledInput
                            id="office_phone"
                            controlType={"input"}
                            value={user?.office_phone}
                            labelName={"Office Phone"}
                            register={register}
                            errors={errors}
                            isPhone={true}
                        />
                    </GSPSInputContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                    <GSPSInputContainer>
                        <GSPSLabelledInput
                            id="first_name"
                            controlType={"input"}
                            value={user?.first_name}
                            labelName={"First Name"}
                            isRequired={true}
                            register={register}
                            errors={errors}
                        />
                    </GSPSInputContainer>
                </Col>
                <Col xs={10} md={6}>
                    <GSPSInputContainer>
                        <GSPSLabelledInput
                            id="mobile_phone"
                            controlType={"input"}
                            value={user?.mobile_phone}
                            labelName={"Mobile Phone"}
                            register={register}
                            errors={errors}
                            isPhone={true}
                        />
                    </GSPSInputContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                    <GSPSInputContainer>
                        <GSPSLabelledInput
                            id="last_name"
                            controlType={"input"}
                            value={user?.last_name}
                            labelName={"Last Name"}
                            isRequired={true}
                            register={register}
                            errors={errors}
                        />
                    </GSPSInputContainer>
                </Col>
                {isAdmin && (
                    <Col xs={10} md={6}>
                        {userGroupPermissions && userGroupPermissions.length > 0 && (
                            <GSPSInputContainer>
                                <GSPSLabelledSelectField
                                    inputID={"user_group_permission"}
                                    labelName={"Permissions"}
                                    defaultValue={user?.group_permission?.id}
                                    selectableOptions={
                                        isGroupAdmin ?
                                            userGroupPermissions.filter(
                                                (perm) => perm.value !== UserPermissionGroupIDs.SUPER_ADMIN,
                                            ) :
                                            userGroupPermissions
                                    }
                                    register={register}
                                    errors={errors}
                                    isRequired={true}
                                />
                            </GSPSInputContainer>
                        )}
                    </Col>
                )}

            </Row>
            <Row>
                <Col xs={10} md={6}>
                    {userTitles &&
                        <GSPSInputContainer>
                            <GSPSLabelledSelectField
                                inputID={"user_title"}
                                labelName={"Title"}
                                defaultValue={user?.role.id}
                                selectableOptions={userTitles}
                                register={register}
                                errors={errors}
                                isRequired={true}
                            />
                        </GSPSInputContainer>
                    }
                </Col>
                <Col xs={10} md={6}>
                    <GSPSInputContainer>
                        <GSPSLabelledInput
                            id="created_at"
                            controlType={"input"}
                            value={TimeZonesUtils.convertToEst(user?.created_at)}
                            labelName={"Date Created"}
                            isDisabled={true}
                            register={register}
                            errors={errors}
                        />
                    </GSPSInputContainer>
                </Col>
            </Row>
            <Row>
                <Col xs={10} md={6}>
                    {userIndustryTypes &&
                        <GSPSInputContainer>
                            <GSPSLabelledSelectField
                                inputID={"user_industry_type"}
                                labelName={"Industry User Type"}
                                defaultValue={user?.industry_type.id}
                                selectableOptions={userIndustryTypes}
                                register={register}
                                errors={errors}
                                isRequired={true}
                            />
                        </GSPSInputContainer>
                    }
                </Col>
                <Col xs={10} md={6}>
                    {user &&
                        <GSPSEnableDisableSwitch
                            inputId={"is_active"}
                            defaultValue={user?.is_active}
                            register={register}
                        />}
                </Col>
            </Row>
            <GSPSPopUpFooter>
                <GSPSCancelButton
                    controlSize={2}
                    buttonText={"Cancel"}
                    onClick={() => {
                        setShow(false);
                    }}
                />
                <GSPSSubmitButton
                    controlSize={2}
                    buttonText={"Save"}
                />
            </GSPSPopUpFooter>
        </Form>
    );
};

UserFormFields.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    setShow: PropTypes.func.isRequired,
    user: PropTypes.object,
};
