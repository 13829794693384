import React from "react";
import {NavLink} from "react-router-dom";
import {GSPSDataDrivenTable} from "Components/DataTable/GSPSDataDrivenTable";
import {useFetchSubscribersQuery} from "api/SubasciberTableAPI";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";

export const Subscribers = () => {
    const tableColumns = {
        "ID": "id",
        "Name": "name",
        "Address": "address.full_address",
        "Phone": "phone",
    };
    const dataParser = {
        id: (subscriber) =>
            <NavLink
                to={{pathname: `/subscribers/${subscriber.id}`}}
                value={subscriber.id}>{subscriber.name}
                {subscriber.id}
            </NavLink>,
    };

    return (
        <>
            <GSPSBreadCrumbs />
            <div className="container">
                <GSPSDataDrivenTable
                    columns={tableColumns}
                    dataParser={dataParser}
                    title="Subscribers"
                    useQuery={useFetchSubscribersQuery} />
            </div>
        </>
    );
};
